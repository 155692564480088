import React, { forwardRef, ReactNode, useEffect, useImperativeHandle, useRef, useState, useContext } from "react";
import { useRouter } from "next/router";
import classNames from "classnames";
import { AppContext } from "@vahak/core/dist/app-context";

//Style
import Styles from "./SideNavLayout.module.scss";

//Hook
import useMediaQuery from "@vahak/core-ui/dist/hooks/useMediaQuery";

// Components
import CustomLoader from "@vahak/core/dist/components/custom-loader/CustomLoader";

//Icons
import VahakLogoLightBlue from "@vahak/core/dist/icons/VahakLogoLightBlue.svg";
import VahakLogoLightBlueSmall from "@vahak/core/dist/icons/Vahak_Nav_Logo.svg";
import PostLoadLorryIcon from "@vahak/core/dist/icons/Post_Nav_Load_Lorry.svg";
import PlusBlueIcon from "@vahak/core/dist/icons/add_blue.svg";

//Methods
import { NavItemsType } from "../methods/useGetLayoutOptions";

//Hooks
import { useToggleModalWithLocalState } from "@vahak/core/dist/hooks/useToggleModalWithLocalState";
import { IDs } from "@vahak/core/dist/constants/automation";

import { ROUTES } from "@vahak/core/dist/constants/routeStrings";

import { GA4EventNames, GA4EventNamesAdminConsole } from "@vahak/core/dist/constants/event-names";

import Dropdown from "@vahak/core/dist/components/dropdown/Dropdown";
import Button from "@vahak/core-ui/dist/components/Button/Button";
import COLORS from "@vahak/core-ui/dist/constants/colors";
import Typography from "@vahak/core-ui/dist/components/Typography";
import AvatarImage from "../../utils/avatar-image/AvatarImage";
import { moEngageUserLogOut } from "@vahak/core/dist/_services/user-analytics.service";
import { useEventTrackerService } from "@vahak/core/dist/_services/hooks/useEventTrackerService";
import { MarketType } from "@vahak/core/dist/custom-types/market-type";
import useValidateUserStatus from "../../../hooks/useValidateUserStatus";
import { LORRY_POSTING_FORM_LOCATION_STATE } from "../../posting/lorry-posting/helpers/constants";
import { LOAD_POSTING_FORM_LOCATION_STATE } from "../../posting/load-posting/helpers/constants";
import { handleLogout } from "@vahak/core/dist/methods/handleLogout";
import { useFeatureByServiceType } from "../../../hooks/useFeatureByServiceType";
import { getEventSourceScreenAdminConsole } from "../../../utils/getEventSourceScreenAdminConsole";
import { useCsDashboard } from "@vahak/core/dist/hooks/useCsDashboard";
import { ADMIN_OPERATION } from "../../../constants/adminOperation";
import { CompanyServiceType } from "@vahak/core/dist/_services/auth.service";
import { POSTING_FORM_MODAL_ORIGINATION_PAGE, useLoadLorryPosting } from "../../../context/load-lorry-posting";

interface SideNavLayoutProps {
    children: ReactNode;
    isCollapsed?: boolean;
    navMenuOption: NavItemsType[];
    activeTab?: activeMenuType;
    toggleSideBar?: () => void;
}

interface activeMenuType {
    menuId?: string;
    subMenuId?: string;
}

export type SidebarFCRef = {
    toggleSidBar: () => void;
};

const SideNavLayout = forwardRef<SidebarFCRef, SideNavLayoutProps>(
    ({ children, isCollapsed = false, navMenuOption, activeTab, toggleSideBar }, ref) => {
        const innerRef = useRef<HTMLDivElement>(null);

        //This ref can be used to trigger sidenav function from out side
        useImperativeHandle(
            ref,
            () => {
                return {
                    toggleSidBar() {
                        SideBarToggler();
                    }
                };
            },
            []
        );

        const {
            setHistoryLocation,
            companyLogo,
            name,
            serviceType,
            companyId,
            userId,
            csDashboardUserRoles,
            customerCompanyId,
            companyLocation,

            //These are used in logout function
            setCompanyId,
            setIsPhoneVerified,
            setName,
            setLang,
            setCompanyLogo,
            setUserId,
            phoneNum,
            setCsDashboardUserRoles,
            setCustomerCompanyId,
            setCustomerName,
            setCustomerPhone,
            setSessionId,
            setThirdPartyCompanyName
        } = useContext(AppContext);
        const [activeMenu, setActiveMenu] = useState<activeMenuType>({ menuId: "", subMenuId: "" });
        const [clickedMenu, setClickedMenu] = useState<activeMenuType>({ menuId: "", subMenuId: "" });

        const desktopScreen = useMediaQuery({ queryType: "desktop" });
        const midScreen = useMediaQuery({ queryType: "medium-screen" });
        const mobileScreen = useMediaQuery({ queryType: "mobile" });
        const { checkPermission, isCsDashboardUser } = useCsDashboard();
        const router = useRouter();

        const {
            openModal: openSideBar,
            closeModal: collapseSideBar,
            toggleModal: SideBarToggler,
            isModalOpen: isSideBarExpanded
        } = useToggleModalWithLocalState();

        useEffect(() => {
            if (desktopScreen) openSideBar();
            if (!mobileScreen && midScreen) collapseSideBar();
            if (mobileScreen) collapseSideBar();
        }, [desktopScreen, midScreen, mobileScreen]);

        const isTso = checkPermission(ADMIN_OPERATION.tagAgent);

        //Events
        const { sendGAandMoEngageEvent } = useEventTrackerService();

        const handleMenuClick = (
            e: React.MouseEvent<HTMLLIElement, MouseEvent>,
            data: { menuId: string; subMenuId?: string },
            menuData: NavItemsType
        ) => {
            /**
             * Events
             */
            isTso &&
                sendGAandMoEngageEvent({
                    name: getEventSourceScreenAdminConsole(menuData.id)?.EVENT_NAME as string,
                    data: {
                        user_id: userId,
                        user_name: name,
                        user_type: GA4EventNamesAdminConsole.ADMIN_USER_TYPE.TSO,
                        company_id: companyId,
                        phone_number: phoneNum,
                        home_city: companyLocation,
                        home_state: companyLocation,
                        source_screen: getEventSourceScreenAdminConsole(menuData.id)?.SOURCE_SCREEN
                    }
                });

            !isCsDashboardUser &&
                sendGAandMoEngageEvent({
                    name: menuData.clientAppEventName as string,
                    data: {
                        from_screen: router.asPath,
                        from: "side_nav"
                    }
                });

            /**
             * To prevent reload of active tab on clicking in side menu
             */
            if (data.subMenuId === undefined && activeTab?.menuId === data.menuId) return;
            e.stopPropagation();
            if (data.subMenuId) {
                setClickedMenu(data);
                setActiveMenu(data);
            } else if (data.menuId === clickedMenu.menuId && !data.subMenuId) {
                setClickedMenu({ menuId: "", subMenuId: "" });
            } else {
                setClickedMenu(data);
            }
            setHistoryLocation?.(window?.location?.pathname);

            let getMenuPath = menuData.pathName;
            if (menuData.subMenu?.length) {
                getMenuPath = menuData.subMenu.find((sub) => sub.id === data.subMenuId)?.pathName || menuData.pathName;
            }

            /**
             * This condition is added only for TSO
             * For TSO we have separate menu load load and lorry marketplace
             * Since the marketplace is server side, we should not do shallow routing
             */

            const shallow = !["tso-load-marketplace", "tso-lorry-marketplace", "ticket-system"].includes(data.menuId);
            getMenuPath &&
                router.push(getMenuPath, undefined, {
                    shallow
                });

            getMenuPath && mobileScreen && collapseSideBar();
        };

        useEffect(() => {
            if (activeTab) {
                setActiveMenu(activeTab);
                if (activeTab.subMenuId) {
                    setClickedMenu(activeTab);
                } else if (activeTab.menuId === clickedMenu.menuId && !activeTab.subMenuId) {
                    setClickedMenu({ menuId: "", subMenuId: "" });
                } else {
                    setClickedMenu(activeTab);
                }
            }
        }, [router]);

        const handleClickOutside = (event: any) => {
            event.stopPropagation();
            if (innerRef.current && !innerRef.current.contains(event.target)) {
                collapseSideBar();
            }
        };

        useEffect(() => {
            /**
             * This will close the sidebar when the user clicks outside the sidebar
             * Only in mobile screen
             */
            if (!mobileScreen) return;
            document.addEventListener("mousedown", handleClickOutside);
            return function cleanup() {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        });

        const {
            openModal: openPostingMenu,
            closeModal: closePostingMenu,
            toggleModal: togglePostingMenu,
            isModalOpen: isPostingMenuOpen
        } = useToggleModalWithLocalState(false);
        const {
            openModal: openUserProfileMenu,
            closeModal: closeUserProfileMenu,
            toggleModal: toggleUserProfileMenu,
            isModalOpen: isUserProfileMenuOpen
        } = useToggleModalWithLocalState(false);

        const { ShowLorryPost, ShowLoadPost } = useFeatureByServiceType();

        /**
         * Load posting verification check
         */
        const { ValidateLoadPosting, ValidateLorryPosting } = useValidateUserStatus();
        const { updateLoadLorryPostingData } = useLoadLorryPosting();

        const handleOpenLoadLorryPostingForm = (marketType: MarketType) => {
            if (marketType === "load") {
                /**
                 * Events
                 */
                sendGAandMoEngageEvent({
                    name: GA4EventNames.SIDE_NAV.post_load_button_click,
                    data: {
                        from_screen: router.asPath
                    }
                });
                updateLoadLorryPostingData?.({
                    isModalOpen: true,
                    type: "load",
                    category: "post",
                    originationPage: POSTING_FORM_MODAL_ORIGINATION_PAGE.SIDE_NAV
                });

                delete router.query[LORRY_POSTING_FORM_LOCATION_STATE.PARAM_NAME];
                router.query[LOAD_POSTING_FORM_LOCATION_STATE.PARAM_NAME] = "load_locations_details";
            } else {
                /**
                 * Events
                 */
                sendGAandMoEngageEvent({
                    name: GA4EventNames.SIDE_NAV.add_lorry_attach_lorry_btn,
                    data: {
                        from_screen: router.asPath
                    }
                });
                updateLoadLorryPostingData?.({
                    isModalOpen: true,
                    type: "lorry",
                    category: "post",
                    originationPage: POSTING_FORM_MODAL_ORIGINATION_PAGE.SIDE_NAV
                });

                delete router.query[LOAD_POSTING_FORM_LOCATION_STATE.PARAM_NAME];
                router.query[LORRY_POSTING_FORM_LOCATION_STATE.PARAM_NAME] = "vehicle_number";
            }

            /**
             * This will check for user verification status and opens aadhaar and pan modal or membership upgrade modal
             */
            if (marketType === "load") {
                ValidateLoadPosting?.(() => {
                    //Callback function
                    router.push(router, undefined, { shallow: true, scroll: false });
                });
            } else {
                ValidateLorryPosting?.(() => {
                    //Callback function
                    router.push(router, undefined, { shallow: true, scroll: false });
                });
            }
        };

        const onClickLogout = async () => {
            handleLogout({
                setCompanyId,
                setIsPhoneVerified,
                setName,
                setLang,
                setCompanyLogo,
                setUserId,
                phoneNum: Number(phoneNum),
                setCsDashboardUserRoles,
                setCustomerCompanyId,
                setCustomerName,
                setCustomerPhone,
                setSessionId,
                setThirdPartyCompanyName
            });

            moEngageUserLogOut();
            if (router.pathname.includes("/marketplace")) {
                delete router?.query?.l;
                Array.isArray(router?.query?.params) && delete router?.query?.params?.[1];
                router.replace(router, undefined, { shallow: false });
            } else {
                router.replace(`${ROUTES.marketplace.getUrl("load")}`, undefined, { shallow: true, scroll: false });
            }
        };

        const isProfilePage = router.asPath.includes("my-profile");

        return (
            <>
                <div className={classNames(Styles.mainLayoutWrapper)}>
                    <nav
                        className={classNames(
                            Styles.sideBarWrapper,
                            isSideBarExpanded || isCollapsed ? Styles.isSideBarExpanded : ""
                        )}
                        ref={innerRef}
                    >
                        <div className={Styles.logoWrapper}>
                            <div
                                id={IDs.common.vahakLogo}
                                // title="Vahak - Book Trucks, Trailers, Containers from Thousands of Trusted Transport businesses and Lorry Owners from All India"
                                onClick={() => {
                                    if (isCsDashboardUser) {
                                        return router.push("/");
                                    }
                                    if (companyId) {
                                        if (router?.pathname?.includes("/dashboard")) return;
                                        router.push(ROUTES.dashboard.getUrl());
                                    } else {
                                        if (router?.pathname?.includes("/marketplace")) return;
                                        if (serviceType == CompanyServiceType.SHIPPER) {
                                            router.push(ROUTES.marketplace.getUrl("lorry"));
                                        } else {
                                            router.push(ROUTES.marketplace.getUrl("load"));
                                        }
                                    }
                                }}
                            >
                                <div className={Styles.logoContainer}>
                                    {midScreen && !isSideBarExpanded ? (
                                        <VahakLogoLightBlueSmall />
                                    ) : (
                                        <VahakLogoLightBlue />
                                    )}
                                </div>
                            </div>
                        </div>
                        {!csDashboardUserRoles ? (
                            <>
                                {/* Posting Icon */}
                                {ShowLoadPost && !ShowLorryPost ? (
                                    <div
                                        className={classNames(Styles.postingBtnContainer)}
                                        onClick={() => handleOpenLoadLorryPostingForm("load")}
                                    >
                                        <div className={classNames(Styles.postingLabelContainer1, Styles.postingBtn)}>
                                            <PostLoadLorryIcon />
                                            <div className={Styles.postingLabel}>
                                                <Typography weight="medium" size="m" color={COLORS.BLUE}>
                                                    Post a Load
                                                </Typography>
                                            </div>
                                        </div>
                                    </div>
                                ) : !ShowLoadPost && ShowLorryPost ? (
                                    <div
                                        className={classNames(Styles.postingBtnContainer)}
                                        onClick={() => handleOpenLoadLorryPostingForm("lorry")}
                                    >
                                        <div className={classNames(Styles.postingLabelContainer1, Styles.postingBtn)}>
                                            <PostLoadLorryIcon />
                                            <div className={Styles.postingLabel}>
                                                <Typography weight="medium" size="m" color={COLORS.BLUE}>
                                                    Attach a lorry
                                                </Typography>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <></>
                                )}
                                {ShowLoadPost && ShowLorryPost ? (
                                    <div className={classNames(Styles.postingIconContainer)}>
                                        <Dropdown
                                            isOpen={isPostingMenuOpen}
                                            DropdownToggleBtn={
                                                <div className={classNames(Styles.postingLabelContainer)}>
                                                    <PostLoadLorryIcon />
                                                    <div className={Styles.postingLabel}>
                                                        <Typography weight="medium" size="m" color={COLORS.BLUE}>
                                                            Post
                                                        </Typography>
                                                    </div>
                                                </div>
                                            }
                                            handleToggle={() => {
                                                /**
                                                 * Events
                                                 */
                                                sendGAandMoEngageEvent({
                                                    name: GA4EventNames.SIDE_NAV.post_btn_clicked,
                                                    data: {
                                                        from_screen: router.asPath
                                                    }
                                                });
                                                togglePostingMenu();
                                            }}
                                            onBlur={closePostingMenu}
                                            containerClassName={Styles.postingMenuDropdownContainer}
                                            direction="right"
                                            dropdownClassName={Styles.dropdownMenuItemClassName}
                                            dropdownToggleClassName={Styles.dropdownToggleClassName}
                                        >
                                            <div className={Styles.postingButtonWrapper}>
                                                <Button
                                                    blockBtn
                                                    fillType="tonal"
                                                    onClick={() => handleOpenLoadLorryPostingForm("load")}
                                                    startIcon={<PlusBlueIcon />}
                                                >
                                                    <Typography weight="regular" size="m">
                                                        Post a Load
                                                    </Typography>
                                                </Button>
                                                <Button
                                                    blockBtn
                                                    fillType="tonal"
                                                    onClick={() => handleOpenLoadLorryPostingForm("lorry")}
                                                    startIcon={<PlusBlueIcon />}
                                                >
                                                    <Typography weight="regular" size="m">
                                                        Attach a Lorry
                                                    </Typography>
                                                </Button>
                                            </div>
                                        </Dropdown>
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </>
                        ) : (
                            <></>
                        )}
                        <div className={Styles.navItemsContainer}>
                            <div className={Styles.menuItemsWrapper}>
                                <ul className={classNames(Styles.menuItems)}>
                                    {navMenuOption.map((menuOption) => {
                                        const { id, label, Icon, IconActive, pathName, subMenu, SubMenuToggleIcon } =
                                            menuOption;
                                        return (
                                            <li
                                                key={id}
                                                id={id}
                                                className={classNames(
                                                    Styles.menuItemWrapper,
                                                    activeMenu.menuId === id && Styles.active,
                                                    activeTab?.menuId === id && !subMenu && Styles.disableClick
                                                )}
                                                onClick={(e) => handleMenuClick(e, { menuId: id }, menuOption)}
                                            >
                                                <div className={classNames(Styles.menuItem)}>
                                                    <span className={Styles.menuIcon}>
                                                        {activeMenu.menuId === id ? (
                                                            IconActive ? (
                                                                <IconActive />
                                                            ) : (
                                                                <></>
                                                            )
                                                        ) : Icon ? (
                                                            <Icon />
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </span>
                                                    <span className={classNames(Styles.label, Styles.arowDown)}>
                                                        {label}{" "}
                                                        {subMenu?.length && SubMenuToggleIcon ? (
                                                            <SubMenuToggleIcon />
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </span>
                                                </div>

                                                {/* Sub menu */}
                                                {subMenu && subMenu.length ? (
                                                    <ul
                                                        className={classNames(
                                                            Styles.subMenuItems,
                                                            (clickedMenu.menuId === id || activeMenu.menuId === id) &&
                                                                Styles.showSubmenu
                                                        )}
                                                    >
                                                        {subMenu.map((subMenu) => {
                                                            const {
                                                                id: subMenuId,
                                                                label,
                                                                Icon,
                                                                IconActive,
                                                                pathName,
                                                                subMenu: nestedSubMenu,
                                                                SubMenuToggleIcon
                                                            } = subMenu;
                                                            return (
                                                                <li
                                                                    key={subMenuId}
                                                                    id={subMenuId}
                                                                    className={classNames(
                                                                        Styles.subMenuItem,
                                                                        activeMenu.subMenuId === subMenuId &&
                                                                            Styles.subMenuActive
                                                                    )}
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        handleMenuClick(
                                                                            e,
                                                                            {
                                                                                menuId: id,
                                                                                subMenuId: subMenuId
                                                                            },
                                                                            menuOption
                                                                        );
                                                                    }}
                                                                >
                                                                    <span className={Styles.subMenuIcon}>
                                                                        {activeMenu.subMenuId === subMenuId ? (
                                                                            IconActive ? (
                                                                                <IconActive />
                                                                            ) : (
                                                                                <></>
                                                                            )
                                                                        ) : Icon ? (
                                                                            <Icon />
                                                                        ) : (
                                                                            <></>
                                                                        )}
                                                                    </span>
                                                                    <span className={classNames(Styles.subMenuLabel)}>
                                                                        {label}
                                                                    </span>
                                                                </li>
                                                            );
                                                        })}
                                                    </ul>
                                                ) : (
                                                    <></>
                                                )}
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        </div>
                        {!csDashboardUserRoles ? (
                            isProfilePage ? (
                                <div style={{ position: "absolute", bottom: "20px", left: "0", width: "inherit" }}>
                                    <Button
                                        blockBtn
                                        fillType="text"
                                        onClick={onClickLogout}
                                        id={IDs.profileDropDown.profileLogout}
                                    >
                                        <Typography weight="regular" size="m" color={COLORS.WHITE}>
                                            Logout
                                        </Typography>
                                    </Button>
                                </div>
                            ) : (
                                <div className={classNames(Styles.userProfileIconContainer)}>
                                    <Dropdown
                                        isOpen={isUserProfileMenuOpen}
                                        DropdownToggleBtn={
                                            <div
                                                className={classNames(Styles.userProfileLabelContainer)}
                                                id={IDs.profileDropDown.profileDropdownBtn}
                                            >
                                                <AvatarImage imageUrl={companyLogo} firstName={name} size="xs" />
                                                <div className={Styles.userProfileLabel}>
                                                    <Typography weight="medium" size="m" color={COLORS.WHITE}>
                                                        {name ?? "My profile"}
                                                    </Typography>
                                                </div>
                                            </div>
                                        }
                                        handleToggle={() => {
                                            /**
                                             * Events
                                             */
                                            !isCsDashboardUser &&
                                                sendGAandMoEngageEvent({
                                                    name: GA4EventNames.SIDE_NAV.profile_menu_clicked,
                                                    data: {
                                                        from_screen: router.asPath,
                                                        from: "side_nav"
                                                    }
                                                });
                                            toggleUserProfileMenu();
                                        }}
                                        onBlur={closeUserProfileMenu}
                                        containerClassName={Styles.userProfileMenuDropdownContainer}
                                        direction="left"
                                        dropdownClassName={Styles.dropdownMenuItemClassName}
                                        dropdownToggleClassName={Styles.dropdownToggleClassName}
                                    >
                                        <div className={Styles.userProfileButtonWrapper}>
                                            <Button
                                                blockBtn
                                                fillType="text"
                                                onClick={() => {
                                                    /**
                                                     * Events
                                                     */
                                                    !isCsDashboardUser &&
                                                        sendGAandMoEngageEvent({
                                                            name: GA4EventNames.SIDE_NAV.profile_icon_clicked,
                                                            data: {
                                                                from_screen: router.asPath,
                                                                from: "side_nav"
                                                            }
                                                        });
                                                    setHistoryLocation?.(router.asPath);
                                                    router.push(ROUTES.userProfile.getUrl(), undefined, {
                                                        shallow: true,
                                                        scroll: false
                                                    });
                                                }}
                                                id={IDs.profileDropDown.myProfileBtn}
                                            >
                                                <Typography weight="regular" size="m">
                                                    My Profile
                                                </Typography>
                                            </Button>
                                            <Button
                                                blockBtn
                                                fillType="text"
                                                onClick={() => {
                                                    /**
                                                     * Events
                                                     */
                                                    !isCsDashboardUser &&
                                                        sendGAandMoEngageEvent({
                                                            name: GA4EventNames.SIDE_NAV.user_logout,
                                                            data: {
                                                                from_screen: router.asPath,
                                                                from: "side_nav"
                                                            }
                                                        });

                                                    onClickLogout();
                                                }}
                                                id={IDs.profileDropDown.profileLogout}
                                            >
                                                <Typography weight="regular" size="m">
                                                    Logout
                                                </Typography>
                                            </Button>
                                        </div>
                                    </Dropdown>
                                </div>
                            )
                        ) : (
                            <></>
                        )}
                    </nav>

                    <div className={Styles.siteMainContent}>
                        {/* TODO: gowdham, refactor loader responsive for mobile then remove this condition */}
                        {desktopScreen || midScreen ? <CustomLoader /> : <></>}
                        {children}
                    </div>
                </div>
            </>
        );
    }
);

export default SideNavLayout;
