import { MarketPlaceType } from "@vahak/core/dist/constants";
import { LORRY_CATEGORIES_ENUM } from "@vahak/core/dist/constants/lorryTypes";
import { PlaceSearchResult } from "@vahak/core/dist/custom-types";
import { createContext, useReducer } from "react";

export interface IMarketplaceConfig {
    srcPlace?: PlaceSearchResult | undefined;
    destPlace?: PlaceSearchResult | undefined;
    srcText?: string | undefined;
    destText?: string | undefined;
    marketType?: MarketPlaceType;
    lorryTypeIds?: LORRY_CATEGORIES_ENUM[];
    selectedLorryId?: number;
    selectLorryNumber?: string;
}

interface IMarketplaceContext {
    marketplaceConfig: IMarketplaceConfig;
    dispatchMarketplace: any;
}

const initialMarketplaceConfig: IMarketplaceConfig = {
    srcPlace: undefined,
    destPlace: undefined,
    srcText: "",
    destText: "",
    marketType: undefined,
    lorryTypeIds: [],
    selectedLorryId: undefined,
    selectLorryNumber: undefined
};

const MARKETPLACE_ACTIONS = { APPLY_FILTER: "APPLY_FILTER", CLEAR_FILTER: "CLEAR_FILTER" };

const reducer = (state: IMarketplaceConfig, action: any) => {
    switch (action.type) {
        case MARKETPLACE_ACTIONS.APPLY_FILTER:
            return { ...state, ...action.payload };
        case MARKETPLACE_ACTIONS.CLEAR_FILTER:
            return initialMarketplaceConfig;
        default:
            return state;
    }
};

const MarketplaceContext = createContext<IMarketplaceContext>({
    marketplaceConfig: initialMarketplaceConfig,
    dispatchMarketplace: () => {}
});

const MarketplaceProvider = ({ children }: { children: React.ReactNode }) => {
    const [marketplaceConfig, dispatchMarketplace] = useReducer<React.Reducer<IMarketplaceConfig, any>>(
        reducer,
        initialMarketplaceConfig
    );
    return (
        <MarketplaceContext.Provider value={{ marketplaceConfig, dispatchMarketplace }}>
            {children}
        </MarketplaceContext.Provider>
    );
};

export { MarketplaceProvider, MarketplaceContext, MARKETPLACE_ACTIONS };
