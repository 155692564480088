export const useLocalStorageMethod = <Type extends {}>() => {
    const getLocalData = (keyName: string, handler?: (d: Type) => Type): Type => {
        let data = localStorage.getItem(keyName);
        const result = data ? JSON.parse(data) : {};
        return handler ? handler(result) : result;
    };

    const setLocalData = (keyName: string, data: Type) => {
        localStorage.setItem(keyName, JSON.stringify(data));
    };

    const removeLocalData = (keyName: string) => {
        localStorage.removeItem(keyName);
    };

    return { getLocalData, setLocalData, removeLocalData };
};
