// ConfigContext.tsx
import { REMOTE_CONSTANTS_URL } from "@vahak/core/dist/constants";
import React, { createContext, useContext, useEffect, useState } from "react";
import CONSTANTS from "./constants.json";

// Define a type for your config
interface Config extends Record<string, any> {}

// Create the context
const ConfigContext = createContext<Config | null>(null);

// Create a hook to access the context
export const useDynamicConfig = () => {
    return useContext(ConfigContext);
};

// Provider component to fetch config and provide it to the app
export const ConfigProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [config, setConfig] = useState<Config | null>(CONSTANTS);

    const fetchConfig = async () => {
        try {
            const response = await fetch(REMOTE_CONSTANTS_URL);
            const result = await response.json();
            setConfig((c) => ({ ...c, ...result }));
        } catch (error) {
            console.log("Failed to fetch config:", error);
        }
    };

    useEffect(() => {
        // fetchConfig();
    }, []);

    return <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>;
};
