import { CommonProps } from "@vahak/core-ui/dist/types";
import { UserLoadInfoTypes, UserLorryInfoTypes } from "@vahak/core/dist/custom-types";
import { createContext, useState, useContext } from "react";
import { LoadAllDetails } from "./services/load/useGetLoadAllDetailsV2/type";
import { PaymentInfo } from "./services/bid/useGetBidPaymentInfo";
import { LorryCardV2 } from "./services/lorry/commonType/type";
import { useQueryClient } from "react-query";
import { QueryNames } from "@vahak/core/dist/_services/hooks/queryNames";

export interface IDetailsPageContextProvider extends CommonProps {}

export const DetailsPageContextProvider = ({ children }: IDetailsPageContextProvider) => {
    const queryClient = useQueryClient();
    const [detailsPageData, setDetailsPageData] = useState<IDetailsPageContextData>({});

    const refetchLoadDetails = (cb?: () => void) => {
        if (detailsPageData.loadDetailsV2?.load_detail?.id)
            queryClient
                .refetchQueries(QueryNames.getLoadAllData, {
                    inactive: true,
                    active: true,
                    stale: true
                })
                .then(() => {
                    cb?.();
                });
    };

    const refetchLorryDetails = (cb?: () => void) => {
        if (detailsPageData.lorryDetailsV2?.lorry_detail?.id)
            queryClient
                .refetchQueries(QueryNames.getLorryAllDataV2, {
                    inactive: true,
                    active: true,
                    stale: true
                })
                .then(() => {
                    cb?.();
                });
    };

    const refetchGDocApi = (cb?: () => void) => {
        queryClient
            .refetchQueries(QueryNames.getDocumentsNonMutate, {
                inactive: true,
                active: true,
                stale: true
            })
            .then(() => {
                cb?.();
            });
    };

    const updateDetailsPageData = (details: IDetailsPageContextData) => {
        setDetailsPageData((prevDetails) => ({ ...prevDetails, ...details }));
    };

    const clearDetailsPageContext = () => {
        setDetailsPageData({});
    };

    return (
        <DetailsPageContext.Provider
            value={{
                detailsPageData,
                updateDetailsPageData,
                clearDetailsPageContext,
                refetchLoadDetails,
                refetchLorryDetails,
                refetchGDocApi
            }}
        >
            {children}
        </DetailsPageContext.Provider>
    );
};

export interface docTypeContext {
    name: string;
    url: string;
    status: number;
    createdBy: number;
    groupId?: number;
    ticketId?: number;
    docType?: number;
    timeStamp: number | string;
}
export interface IDetailsPageContextData {
    type?: "load" | "lorry";
    title?: string;
    onClickBack?: () => void;
    onSuccess?: () => void;
    postSuccess?: () => void;
    onFailure?: () => void;
    postFailure?: () => void;
    onSettle?: () => void;
    queriesToInvalidate?: {
        success?: string[];
        error?: string[];
        back?: string[];
    };

    //Main props
    loadInfo?: UserLoadInfoTypes;
    lorryInfo?: UserLorryInfoTypes;
    loadDetailsV2?: LoadAllDetails;
    lorryDetailsV2?: LorryCardV2;
    PaymentInfo?: PaymentInfo[];

    //Doc
    goodsDoc?: docTypeContext;
    lrDoc?: docTypeContext;
    ewayDoc?: docTypeContext;
    supportingDoc?: docTypeContext[];

    //Lorry doc
    podDoc?: docTypeContext;
    rcDoc?: docTypeContext;
    lorryFitnessDoc?: docTypeContext;
    tdsDoc?: docTypeContext;
    vehicleInsuranceDoc?: docTypeContext;
    vehicleOwnerAadhaarDoc?: docTypeContext;
    driverLicenseDoc?: docTypeContext;
    driverAadhaarDoc?: docTypeContext;
    openLorryLocationModal?: boolean;
}

export interface IDetailsPageContext {
    detailsPageData?: IDetailsPageContextData;
    updateDetailsPageData?: (data: IDetailsPageContextData) => void;
    clearDetailsPageContext?: () => void;
    refetchLoadDetails?: () => void;
    refetchLorryDetails?: () => void;
    refetchGDocApi?: () => void;
}

const DetailsPageContext = createContext<IDetailsPageContext>({});

export const useDetailsPageContext = () => useContext(DetailsPageContext);
