import { CompanyServiceType } from "@vahak/core/dist/_services/auth.service";
import { MembershipPlans } from "./vas";

export interface ServiceTypeOptionsType {
    label: string;
    signUpLabel: string;
    shortLabel: string;
    value: string;
    description: string;
    signUpDescription: string;
    icon: string;
    lookingForLabel: string;
    lookingForAlternative: string;
    lookingForIcon: string;
    membershipCategoryIconName: string;
    membershipCategoryActiveIconName: string;
    membershipPlanOrderKey: number;
    membershipServiceTypeId: MembershipPlans;
}

export const ServiceTypeOptions: ServiceTypeOptionsType[] = [
    {
        label: "Truck Owner/Driver",
        signUpLabel: "I am a Lorry Owner",
        shortLabel: "Trucker",
        value: CompanyServiceType.FLEET_OWNER,
        description: "I have truck(s) and I am looking for loads",
        signUpDescription: "I am searching for load near me",
        icon: "/lorry_owner_icon.svg",
        lookingForLabel: "Find Loads",
        lookingForAlternative: "Find Shippers",
        lookingForIcon: "find-load.svg",
        membershipCategoryIconName: "/Membership_trucker.svg",
        membershipCategoryActiveIconName: "/Membership_trucker_white.svg",
        membershipPlanOrderKey: 2,
        membershipServiceTypeId: MembershipPlans.TRUCKER
    },
    {
        label: "Shipper",
        signUpLabel: "I am a Shipper",
        shortLabel: "Shipper",
        value: CompanyServiceType.SHIPPER,
        description: "I have loads and I am searching for trucks",
        signUpDescription: "I am searching for a lorry",
        icon: "/load_owner_icon.svg",
        lookingForLabel: "Find Lorries",
        lookingForAlternative: "Find Truck owners",
        lookingForIcon: "find-lorry.svg",
        membershipCategoryIconName: "/Membership_shipper.svg",
        membershipCategoryActiveIconName: "/Membership_shipper_white.svg",
        membershipPlanOrderKey: 1,
        membershipServiceTypeId: MembershipPlans.SHIPPER
    },
    {
        label: "Transporter",
        signUpLabel: "I am a Transporter / Broker",
        shortLabel: "Transporter",
        value: CompanyServiceType.TRANSPORT_CONTRACTOR,
        description: "I am searching for both loads and lorries",
        signUpDescription: "I am searching for  load and lorry",
        icon: "/transporter_icon.svg",
        lookingForLabel: "Find Loads & Lorries",
        lookingForAlternative: "Find Both",
        lookingForIcon: "find-load-lorry.svg",
        membershipCategoryIconName: "/Membership_transporter.svg",
        membershipCategoryActiveIconName: "/Membership_transporter_white.svg",
        membershipPlanOrderKey: 3,
        membershipServiceTypeId: MembershipPlans.TRANSPORTER
    }
];
