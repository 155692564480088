import { useContext } from "react";
import { AppContext } from "@vahak/core/dist/app-context";
import { CompanyServiceType } from "@vahak/core/dist/_services/auth.service";
import { useCsDashboard } from "@vahak/core/dist/hooks/useCsDashboard";
import { ADMIN_OPERATION } from "../constants/adminOperation";

export function useFeatureByServiceType(externalServiceType?: string) {
    const { customerServiceType, serviceType } = useContext(AppContext);
    const { checkPermission, isCsDashboardUser } = useCsDashboard();

    /**
     * We may remove this check in future
     * This can be removed and modify according to requirement
     */

    // To check whether current CS User has load / lorry edit access
    const csUserViewPermission = checkPermission(ADMIN_OPERATION.profileView);
    const csUserEditPermission = checkPermission(ADMIN_OPERATION.userLoadLorryAction);

    if (isCsDashboardUser) {
        return {
            showLoadFeatures: csUserViewPermission,
            showLorryFeatures: csUserViewPermission,
            showLoadAndLorryFeatures: csUserViewPermission,
            ShowLoadPost: csUserEditPermission,
            ShowLorryPost: csUserEditPermission
        };
    }
    switch (externalServiceType || customerServiceType || serviceType) {
        case CompanyServiceType.SHIPPER:
            return {
                showLoadFeatures: false,
                showLorryFeatures: true,
                showLoadAndLorryFeatures: false,
                ShowLoadPost: true,
                ShowLorryPost: false
            };
        case CompanyServiceType.FLEET_OWNER:
            return {
                showLoadFeatures: true,
                showLorryFeatures: false,
                showLoadAndLorryFeatures: false,
                ShowLoadPost: false,
                ShowLorryPost: true
            };

        case CompanyServiceType.AGENT_BROKER:
        case CompanyServiceType.TRANSPORT_CONTRACTOR:
        case CompanyServiceType.DEPRECATED_SERVICE_TYPE:
        default:
            return {
                showLoadFeatures: true,
                showLorryFeatures: true,
                showLoadAndLorryFeatures: true,
                ShowLoadPost: true,
                ShowLorryPost: true
            };
    }
}
